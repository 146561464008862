import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { getMomentObject } from '@common/soc-react-kit/src/utils/momentHelpers';
import AlertFilterTypeahead from '@soc/alerts/src/components/AlertFilterTypeahead';
import { getAccessToken, getHeaderLanguage, getTokenType } from '@soc/kit/api';
import { createSelector } from 'reselect';

import { fetchTags } from 'components/AlertList/actions';
import { usersLimit } from 'store/reducers/common';
import { socProperties } from 'utils/brending';

import { calculateData } from '../../components/MitreWidget/helper';
import { calculateData as calculateRulesData } from '../../components/RulesMitreWidget/helpers';
import {
    API_URL_SOC,
    ARTICLES_ENTITY,
    CUSTOMER_ASSIGNEE_FIELD,
    encodeIncidentsFilter,
    encodeInstructionsFilter,
    encodeLogSourcesFilter,
    encodeRulesFilter,
    ID_FIELD,
    INCIDENTS_ENTITY,
    INSTRUCTIONS_ENTITY,
    LOGSOURCES_ENDPOINT,
    PARSED_ACTIVITY_DETECTED,
    RESPONSE_INSTRUCTIONS_ENTITY,
    RULE_ENDPOINT,
    RULES_ENTITY,
    RULES_ID,
    CATEGORY_FIELD,
    CATEGORY_FIELD_FILTER,
    RULES_TECHNIQUE,
    TAGS,
    USERS_ENDPOINT,
    RESOLUTION_NAME_FIELD,
    TYPE_FIELD, RESOLUTION_NAME_FIELD_TITLE,
} from '../../services/api';
import widgets from '../../services/widgets';

import articleCard from './articleCard/reducer';
import articles, * as fromArticles from './articles/reducer';
import auth, * as fromAuth from './auth/reducer';
import changePassword from './changePassword/reducer';
import cmdbAssetPage, * as fromCmdbAssetPage from './cmdbAssetPage/reducer';
import createReport, * as fromCreateReport from './createReport/reducer';
import dashboard, * as fromDashboard from './dashboard/reducer';
import editReport, * as fromEditReport from './editReport/reducer';
import epsWidget, * as fromEpsWidget from './epsWidget/reducer';
import fileDownloader from './fileDownloader/reducer';
import filters, * as fromFilters from './filters/reducer';
import incCard, * as fromIncCard from './incCard/reducer';
import incidentList, * as fromIncidentList from './incidentList/reducer';
import instructions, * as fromInstructions from './instructions/reducer';
import instructionCard, * as fromInstructionCard from './instuctionCard/reducer';
import mitreWidget, * as fromMitreWidget from './mitre/reducer';
import protectedSystems, * as fromProtectedSystems from './protectedSystems/reducer';
import reports, * as fromReports from './reports/reducer';
import reportsShedules, * as fromReportsShedules from './reportsShedules/reducer';
import responseInstructionCard from './responseInstructionCard/reducer';
import responseInstructions, * as fromResponseInstructions from './responseInstructions/reducer';
import ruleList, * as fromRuleList from './ruleList/reducer';
import rulePage, * as fromRulePage from './rulePage/reducer';
import sentByEmail from './sentByEmail/reducer';
import theme, * as fromTheme from './theme/reducer';

export default {
  dashboard,
  auth,
  incCard,
  protectedSystems,
  reports,
  incidentList,
  filters,
  fileDownloader,
  sentByEmail,
  changePassword,
  mitreWidget,
  instructions,
  instructionCard,
  articles,
  articleCard,
  responseInstructions,
  responseInstructionCard,
  createReport,
  reportsShedules,
  editReport,
  rulePage,
  ruleList,
  theme,
  epsWidget,
  cmdbAssetPage,
};
// AUTH
export const refreshToken = (state) => fromAuth.refreshToken(state.auth);
export const accessToken = (state) => fromAuth.accessToken(state.auth);
export const isAuthenticated = (state) => fromAuth.isAuthenticated(state.auth);
export const isTokenFetching = (state) => fromAuth.isTokenFetching(state.auth);
export const authHeaderType = (state) => fromAuth.authHeaderType(state.auth);
export const getUser = (state) => fromAuth.getUser(state.auth);
export const getUsername = (state) => getUser(state).username;
export const getIsMounted = (state) => fromAuth.getIsMounted(state.auth);
export const getMfaToken = (state) => fromAuth.getMfaToken(state.auth);
export const getTimeToGetNextMfaToken = (state) =>
  fromAuth.getTimeToGetNextMfaToken(state.auth);
export const getOidcUserManager = (state) =>
  fromAuth.oidcUserManager(state.auth);

export const authErrorsSelector = createSelector(
  [(state) => state.auth.errors],
  (errors) => {
    return Object.keys(errors || {}).reduce((res, key) => {
      const error = errors[key];
      return Array.isArray(error) ? [...res, ...error] : [...res, error];
    }, []);
  },
);

// FILTERS
export const getIncidentsDateRange = (state) =>
  fromFilters.getIncidentsDateRange(state.filters);
export const getSelectedFilters = (entity, state) =>
  fromFilters.getSelectedFilters(entity, state.filters);
export const getFiltersSettings = (entity, state, dispatch) => {
  if (entity === INCIDENTS_ENTITY) {
    return getIncidentFiltersSettings(entity, state, dispatch);
  }
  return fromFilters.getFiltersSettings(entity, state.filters);
};
export const getFiltersTagText = (entity, state) =>
  fromFilters.getFiltersTagText(entity, state.filters);
export const getIsShowFiltersPanel = (entity, state) =>
  fromFilters.getIsShowFiltersPanel(entity, state.filters);
export const getOrdering = (entity, state) =>
  fromFilters.getOrdering(entity, state.filters);
export const getSearch = (entity, state) =>
  fromFilters.getSearch(entity, state.filters);

export const urlEncodedIncFiltersSelector = createSelector(
  [
    (state) => getSelectedFilters('incidents', state),
    (state) => getOrdering('incidents', state),
    (state) => getSearch('incidents', state),
  ],
  (filters, ordering, search) =>
    encodeIncidentsFilter(filters, undefined, undefined, ordering, search),
);

export const makeIsShowFiltersSelector = (entity) => {
  return createSelector(
    [
      (state) => getSelectedFilters(entity, state),
      (state) => getIsShowFiltersPanel(entity, state),
    ],
    (selectedFilters, isShow) => {
      const filtersLength = Object.keys(selectedFilters).length;

      return isShow || filtersLength > 0;
    },
  );
};

export const dateRangeIsChangedSelector = createSelector(
  [getIncidentsDateRange],
  (dateRange) => {
    const test = [
      getMomentObject(new Date()).subtract(6, 'days'),
      getMomentObject(new Date()),
    ];

    return !(
      dateRange[0].startOf('day').isSame(test[0].startOf('day')) &&
      dateRange[1].endOf('day').isSame(test[1].endOf('day'))
    );
  },
);

export function getFilterParams({ entity, state }) {
  const search = getSearch(entity, state);
  const filters = getSelectedFilters(entity, state);
  const ordering = getOrdering(entity, state);
  const locale = currentLocale(state);

  switch (entity) {
    case INCIDENTS_ENTITY:
      return encodeIncidentsFilter(
        filters,
        undefined,
        undefined,
        ordering,
        search,
      );

    case INSTRUCTIONS_ENTITY:
    case RESPONSE_INSTRUCTIONS_ENTITY:
    case ARTICLES_ENTITY:
      return encodeInstructionsFilter(filters, ordering, search, locale);

    case RULES_ENTITY:
      return encodeRulesFilter(filters, ordering, search, locale);

    default:
      break;
  }
}

// INC CARD
export const displayedIncident = (state) => fromIncCard.incident(state.incCard);
export const incCardFetchError = (state) =>
  fromIncCard.fetchError(state.incCard);
export const incCardAddCommentSubmitting = (state) =>
  fromIncCard.addCommentSubmitting(state.incCard);
export const incCardAddCommentErrors = (state) =>
  fromIncCard.addCommentErrors(state.incCard);
export const gossopkaLoading = (state) =>
  fromIncCard.gossopkaLoading(state.incCard);
export const incCardComments = (state) =>
  fromIncCard.commentsData(state.incCard);
export const incCardCommentsIsFetching = (state) =>
  fromIncCard.commentsFetching(state.incCard);
export const incCardSelectedAlert = (state) =>
  fromIncCard.selectedAlert(state.incCard);
export const incCardFetchedAlertGroupedData = (state) =>
  fromIncCard.fetchedAlertGroupedData(state.incCard);
export const incCardFetchedAlert = (state) =>
  fromIncCard.fetchedAlert(state.incCard);
export const incCardFetchedAlertTree = (state) =>
  fromIncCard.fetchedAlertTree(state.incCard);
export const incCardFetchedAlertSymptomatic = (state) =>
  fromIncCard.fetchedAlertSymptomatic(state.incCard);
export const incCardAlerts = (state) => fromIncCard.alerts(state.incCard);
export const incCardAlertsFetching = (state) =>
  fromIncCard.alertsFetching(state.incCard);
export const incCardAlertsTotalCount = (state) =>
  fromIncCard.getAlertsTotalCount(state.incCard);
export const incCardAlertsListNexPage = (state) =>
  fromIncCard.getNextPage(state.incCard);
export const incCardAlertsSearch = (state) =>
  fromIncCard.alertsSearch(state.incCard);
export const incCardAlertsHasMore = (state) =>
  fromIncCard.hasMore(state.incCard);
export const incPageCmdbAssets = (state) =>
  fromIncCard.cmdbAssets(state.incCard);
export const incPageCmdbAssetsFetching = (state) =>
  fromIncCard.cmdbAssetsFetching(state.incCard);
export const incPageCmdbAssetsIsFetchingMore = (state) =>
  fromIncCard.cmdbAssetsIsFetchingMore(state.incCard);
export const incPageCmdbAssetsNext = (state) =>
  fromIncCard.cmdbAssetsNext(state.incCard);
export const incPageCmdbAssetsHasMore = (state) =>
  fromIncCard.cmdbAssetsHasMore(state.incCard);

export const makeIncCardAlertsWithSearchSelector = () => {
  return createSelector(
    [incCardAlerts, incCardAlertsSearch],
    (alerts, search) =>
      alerts.filter((alert) =>
        search.length === 0
          ? alert
          : Object.values(alert).find((item) =>
              item?.toString().toLowerCase().includes(search.toLowerCase()),
            ),
      ),
  );
};

// INSTRUCTION CARD
export const instructionCardDisp = (state) =>
  fromInstructionCard.instruction(state.instructionCard);
export const instructionCardFetchError = (state) =>
  fromInstructionCard.fetchError(state.instructionCard);
export const instructionCardAddCommentModal = (state) =>
  fromInstructionCard.addCommentModal(state.instructionCard);
export const instructionCardComments = (state) =>
  fromInstructionCard.getComments(state.instructionCard);
export const instructionCardVersions = (state) =>
  fromInstructionCard.versions(state.instructionCard);

// INCIDENT FILTERS
export const getIncidentFiltersSettings = (entity, state, dispatch) => {
  const initFilters = fromFilters.getFiltersSettings(entity, state.filters);
  const ruleCaption =
    currentLocale(state) === 'en' ? 'rule_caption_eng' : 'rule_caption_rus';
  const getTags = (search, selected) => dispatch(fetchTags(search, selected));
  const techniques = getMitreTechniques(state);
  return {
    ...initFilters,
    [RESOLUTION_NAME_FIELD_TITLE]: {
      label: (
        <Translate
          value={`filterPanel.incidents.types.${RESOLUTION_NAME_FIELD}`}
        />
      ),
      type: 'suggest',
      getEndpoint: (search) => {
        const trimmedSearch = search.trim();
        const apiSearch = trimmedSearch
          ? `&q=title~"${trimmedSearch}" or id ~ "${trimmedSearch}"`
          : '';
        return `${API_URL_SOC}/issues/-/resolutions/?limit=30${apiSearch}`;
      },
      handleResult: (response) =>
        response?.results?.length
          ? response.results.map(({ id, title }) => ({
              value: id,
              text: title,
            }))
          : [],
    },
    [TYPE_FIELD]: {
      label: <Translate value={`filterPanel.incidents.types.${TYPE_FIELD}`} />,
      type: 'suggest',
      getEndpoint: (search) => {
        const trimmedSearch = search.trim();
        const apiSearch = trimmedSearch
          ? `&q=title~"${trimmedSearch}" or id ~ "${trimmedSearch}"`
          : '';
        return `${API_URL_SOC}/issues/issue_types/?limit=30${apiSearch}`;
      },
      handleResult: (response) =>
        response?.results?.length
          ? response.results.map(({ id, title }) => ({
              value: id,
              text: title,
            }))
          : [],
    },
    [RULES_TECHNIQUE]: {
      label: (
        <Translate value={`filterPanel.ruleList.types.${RULES_TECHNIQUE}`} />
      ),
      type: 'multiselect',
      choices: Object.values(techniques).map(({ name, technique_id: key }) => ({
        key,
        text: `${key}: ${name}`,
      })),
    },
    [RULES_ID]: {
      label: <Translate value={`filterPanel.ruleList.types.${RULES_ID}`} />,
      type: 'suggest',
      getEndpoint: (value) => `${RULE_ENDPOINT}?page_size=2000&search=${value}`,
      handleResult: (response) =>
        response.results
          ? response.results.map(({ rule_id, [ruleCaption]: title }) => ({
              value: rule_id,
              text: `${rule_id}: ${title}`,
            }))
          : [],
    },
    [CATEGORY_FIELD]:
      socProperties.brandName !== 'TTC'
        ? {
            label: (
              <Translate
                value={`filterPanel.incidents.types.${CATEGORY_FIELD}`}
              />
            ),
            type: 'suggest',
            getEndpoint: (search) => {
              const trimmedSearch = search.trim();
              const apiSearch = trimmedSearch
                ? `&q= id ~ "${trimmedSearch}" or title ~"${trimmedSearch}"`
                : '';
              return `${API_URL_SOC}/issues/-/categories/?limit=30${apiSearch}`;
            },
            handleResult: (response) =>
              response.length
                ? response.map(({ id, title }) => ({
                    value: id,
                    text: title,
                  }))
                : [],
          }
        : initFilters[CATEGORY_FIELD_FILTER],
    [PARSED_ACTIVITY_DETECTED]: {
      label: (
        <Translate
          value={`filterPanel.incidents.types.${PARSED_ACTIVITY_DETECTED}`}
        />
      ),
      type: 'suggest',
      getEndpoint: (value) =>
        `${LOGSOURCES_ENDPOINT}?page_size=100&search=${value}`,
      handleResult: (response) =>
        response.results
          ? response.results.map(({ id, name }) => ({
              value: id,
              text: name,
            }))
          : [],
    },
    [CUSTOMER_ASSIGNEE_FIELD]: {
      label: (
        <Translate
          value={`filterPanel.incidents.types.${CUSTOMER_ASSIGNEE_FIELD}`}
        />
      ),
      type: 'suggest',
      isActionButtonsShow: true,
      getEndpoint: (value = '') => {
        const search = value.trim() ? `&search=${value.trim()}` : '';
        return `${USERS_ENDPOINT}?${usersLimit}${search}`;
      },
      handleResult: (response) =>
        response.results
          ? response.results.map(({ id, username }) => ({
              value: username,
              text: username,
            }))
          : [],
    },
    [TAGS]: {
      label: I18n.t(`incCard.tags`),
      customFilter: ({ ...props }) => (
        <AlertFilterTypeahead {...props} getSuggestions={getTags} />
      ),
    },
  };
};

// DASHBOARD
export const getAnalytics = (state) =>
  fromDashboard.getAnalytics(state.dashboard);
export const getAnalyticsFetching = (state) =>
  fromDashboard.getAnalyticsFetching(state.dashboard);
export const getIsChartFontsLoaded = (state) =>
  fromDashboard.getIsChartFontsLoaded(state.dashboard);

export const makeAmChartDataSelector = () => {
  return createSelector(
    [
      getAnalytics,
      getIncidentsDateRange,
      (state, props) => props.widgetSettings,
    ],
    (analytics, dateRange, widgetSettings) =>
      widgetSettings.dataGenerator(
        analytics[widgetSettings.field],
        widgetSettings,
        dateRange,
      ),
  );
};
export const makeAmChartEpsDataSelector = () => {
  return createSelector(
    [
      getEpsAnalytic,
      getIncidentsDateRange,
      (state, props) => props.widgetSettings,
    ],
    (analytics, dateRange, widgetSettings) =>
      widgetSettings.dataGenerator(
        analytics[widgetSettings.field],
        widgetSettings,
        dateRange,
      ),
  );
};
export const makeWidgetSelector = () => {
  return createSelector(
    [(state, props) => props.widget.id, (state, props) => props.widget.type],
    (id, type) => widgets.getWidget(id, type),
  );
};

// PROTECTED SYSTEMS
export const protectedSystemsSystems = (state) =>
  fromProtectedSystems.systems(state.protectedSystems);
export const protectedSystemsIsLoading = (state) =>
  fromProtectedSystems.isLoading(state.protectedSystems);

// CREATE REPORT FORM
export const isCreateReportModalOpen = (state) =>
  fromCreateReport.isModalOpen(state.createReport);
export const isCreateReportModalLoading = (state) =>
  fromCreateReport.isLoading(state.createReport);
export const createReportModalSystems = (state) =>
  fromCreateReport.systems(state.createReport);

// I18n
export const currentLocale = (state) => state.i18n.locale;

// REPORTS
export const reportsList = (state) => fromReports.reportsList(state.reports);
export const isLoadingReports = (state) =>
  fromReports.isLoadingReports(state.reports);
export const hasMoreReports = (state) => fromReports.hasMore(state.reports);

// EDIT REPORT FORM
export const isEditReportModalOpen = (state) =>
  fromEditReport.isModalOpen(state.editReport);
export const isEditReportModalLoading = (state) =>
  fromEditReport.isLoading(state.editReport);
export const editReportModalSystems = (state) =>
  fromEditReport.systems(state.editReport);

// REPORTSSHEDULES

export const reportsShedulesNextPage = (state) =>
  fromReportsShedules.getNextPage(state.reportsShedules);
export const selectedShedule = (state) =>
  fromReportsShedules.getSelectedShedule(state.reportsShedules);
export const makeReportsShedulesCardSelector = () => {
  return createSelector(
    [(state, props) => state.reportsShedules.selected.id === props.shedule.id],
    (isSelected) => isSelected,
  );
};

// INCIDENT LIST
export const incidentListSelected = (state) =>
  fromIncidentList.getSelectedIncident(state.incidentList);
export const incidentListNextPage = (state) =>
  fromIncidentList.getNextPage(state.incidentList);
export const makeIncidentCardSelector = () => {
  return createSelector(
    [
      (state, props) =>
        state.incidentList.selected[ID_FIELD] === props.incident[ID_FIELD],
    ],
    (isSelected) => isSelected,
  );
};

// INSTRUCTIONS
export const instructionsNextPage = (state) =>
  fromInstructions.getNextPage(state.logSources);
export const makeInstructionsCardSelector = () => {
  return createSelector(
    [(state, props) => state.instructions.selected.id === props.instruction.id],
    (isSelected) => isSelected,
  );
};

// RESPONSE INSTRUCTIONS
export const responseInstructionNextPage = (state) =>
  fromResponseInstructions.getNextPage(state.responseInstructions);

// ARTICLES
export const articlesNextPage = (state) =>
  fromArticles.getNextPage(state.articles);

// RULE LIST
export const ruleListNexPage = (state) =>
  fromRuleList.getNextPage(state.ruleList);
export const ruleListSelectedMitreTechnique = (state) =>
  fromRuleList.getSelectedMitreTechnique(state.ruleList);
export const getMitreCoverageRules = (state) =>
  fromRuleList.getMitreCoverage(state.ruleList);
export const ruleListTotalCount = (state) =>
  fromRuleList.getRulesTotalCount(state.ruleList);

// RULE PAGE
export const rulePageData = (state) => fromRulePage.getRule(state.rulePage);
export const rulePageIsFetchingRule = (state) =>
  fromRulePage.getIsFetchingRule(state.rulePage);
export const rulePageIncidents = (state) =>
  fromRulePage.getIncidents(state.rulePage);
export const rulePageIsFetchingIncidents = (state) =>
  fromRulePage.getIsFetchingIncidents(state.rulePage);
export const rulePageIncidentsNextPage = (state) =>
  fromRulePage.getIncidentsNextPage(state.rulePage);
export const rulePageHasMoreIncidents = (state) =>
  fromRulePage.getHasMoreIncidents(state.rulePage);

export const withHeaders =
  (headers = {}) =>
  () => {
    const oidcAuthHeaders = {
      'WWW-Authenticate': 'OIDC realm="api"',
      Authorization: `OIDC ${getAccessToken()}`,
    };

    const defaultAuthHeaders = { Authorization: `Bearer ${getAccessToken()}` };

    const authHeaders =
      getTokenType() === 'OIDC' ? oidcAuthHeaders : defaultAuthHeaders;

    return {
      ...headers,
      ...authHeaders,
      ...getHeaderLanguage(),
    };
  };

// MITRE WIDGET
export const getMitreAnalytics = (state) =>
  fromDashboard.getMitre(state.dashboard);
export const getMitreMapping = (state) =>
  fromMitreWidget.getMapping(state.mitreWidget);
export const getMitreTechniques = (state) =>
  fromMitreWidget.getTechniques(state.mitreWidget);
export const getMitreTechniquesIds = (state) =>
  fromMitreWidget.getTechniquesIds(state.mitreWidget);
export const getMitreTactics = (state) =>
  fromMitreWidget.getTactics(state.mitreWidget);
export const getMitreTacticsIds = (state) =>
  fromMitreWidget.getTacticsIds(state.mitreWidget);
export const getMitreTacticsIdsCoverage = (state) =>
  fromMitreWidget.getTacticsIdsCoverage(state.mitreWidget);
export const getMitreIsLoading = (state) =>
  fromMitreWidget.getIsLoading(state.mitreWidget);
export const getMitrePlatforms = (state) =>
  fromMitreWidget.getPlatforms(state.mitreWidget);

export const mitreDataSelector = createSelector(
  [getMitreMapping, getMitreTechniques, getMitreAnalytics],
  calculateData,
);

export const rulesMitreDataSelector = createSelector(
  [getMitreMapping, getMitreTechniques, getMitreCoverageRules],
  calculateRulesData,
);

// THEME
export const getTheme = (state) => fromTheme.getTheme(state.theme);

// EPS WIDGET
export const getEpsAnalytic = (state) =>
  fromEpsWidget.getAnalytic(state.epsWidget);
export const getEpsAnalyticsFetching = (state) =>
  fromEpsWidget.getFetching(state.epsWidget);

// CMDB ASSET PAGE
export const getCmdbAssetPageIncidents = (state) =>
  fromCmdbAssetPage.incidents(state.cmdbAssetPage);
export const getCmdbAssetPageIncidentsFetching = (state) =>
  fromCmdbAssetPage.incidentsFetching(state.cmdbAssetPage);
export const getCmdbAssetPageIncidentsCount = (state) =>
  fromCmdbAssetPage.incidentsCount(state.cmdbAssetPage);
export const getCmdbAssetPageIncidentsNext = (state) =>
  fromCmdbAssetPage.incidentsGetNext(state.cmdbAssetPage);
export const getCmdbAssetPageIncidentsHasMore = (state) =>
  fromCmdbAssetPage.incidentsHasMore(state.cmdbAssetPage);
export const getCmdbAssetPageIncidentsIsFetchingMore = (state) =>
  fromCmdbAssetPage.incidentsIsFetchingMore(state.cmdbAssetPage);
