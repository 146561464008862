import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import * as am4core from '@amcharts/amcharts4/core';

import {
  generateAmchartData,
  generateAmchartTimelineData,
} from '../utils/helpers';

const WIDGET_CREATE_FIELD = 'created';
const WIDGET_PRIORITY_FIELD = 'priority';
const WIDGET_STATUS_FIELD = 'status';
const WIDGET_CATEGORY_FIELD = 'category';
const WIDGET_AFFECTED_SYSTEMS = 'affected_systems';

const ORANGE_COLOR = '#eb6f1c';
const YELLOW_COLOR = '#ebaf09';
const GRAY_COLOR = '#808080';
const BLUE_COLOR = '#41AAD7';
const RED_COLOR = '#e04a3f';
const PINK_COLOR = '#e76c63';

const PRIORITY_COLORS = {
  Low: GRAY_COLOR,
  Medium: YELLOW_COLOR,
  High: ORANGE_COLOR,
  Critical: RED_COLOR,
};

const PIE_CONFIG = {
  series: [
    {
      type: 'PieSeries',
      innerRadius: am4core.percent(80),
      dataFields: {
        value: 'value',
        category: 'category',
      },
      slices: {
        cursorOverStyle: am4core.MouseCursorStyle.pointer,
        stroke: am4core.color('#ffffff'),
        strokeWidth: 0,
        strokeOpacity: 1,
      },
      labels: {
        text: '{value}',
        wrap: true,
        maxWidth: 200,
        fill: am4core.color('#4d4d4d'),
      },
      tooltip: {
        getFillFromObject: false,
        background: {
          fill: am4core.color('rgba(51, 51, 51, 0.9)'),
        },
        label: {
          fill: am4core.color('#ffffff'),
        },
      },
    },
  ],
  legend: {
    type: 'Legend',
    valueLabels: {
      disabled: true,
    },
    markers: {
      children: [
        {
          width: 20,
          height: 20,
          cornerRadiusBottomLeft: 10,
          cornerRadiusBottomRight: 10,
          cornerRadiusTopLeft: 10,
          cornerRadiusTopRight: 10,
        },
      ],
    },
    labels: {
      template: {
        wrap: true,
        maxWidth: 200,
        truncate: false,
        fill: am4core.color('#4d4d4d'),
      },
    },
  },
};

const HORIZONTAL_BAR_CONFIG = {
  yAxes: [
    {
      type: 'CategoryAxis',
      dataFields: {
        category: 'category',
      },
      renderer: {
        minGridDistance: 10,
        grid: {
          location: 0,
        },
        labels: {
          truncate: true,
          maxWidth: 250,
          fill: am4core.color('#4d4d4d'),
        },
      },
      cursorOverStyle: am4core.MouseCursorStyle.pointer,
    },
  ],
  xAxes: [
    {
      type: 'ValueAxis',
      min: 0,
      renderer: {
        labels: {
          fill: am4core.color('#4d4d4d'),
        },
      },
    },
  ],
  series: [
    {
      type: 'ColumnSeries',
      dataFields: {
        categoryY: 'category',
        valueX: 'value',
      },
      columns: {
        cursorOverStyle: am4core.MouseCursorStyle.pointer,
        tooltipText: '{categoryY}: {valueX}',
        template: {
          height: 27,
        },
      },
      bullets: [
        {
          type: 'LabelBullet',
          label: {
            text: "{valueX.value.formatNumber('#.')}",
            truncate: false,
            fill: am4core.color('#4d4d4d'),
          },
          dx: 15,
        },
      ],
      tooltip: {
        getFillFromObject: false,
        background: {
          fill: am4core.color('rgba(51, 51, 51, 0.9)'),
        },
        label: {
          fill: am4core.color('#ffffff'),
        },
      },
    },
  ],
  maskBullets: false,
  paddingRight: 25,
};

const TIMELINE_CONFIG = {
  yAxes: [
    {
      type: 'ValueAxis',
      extraMax: 0.1,
      min: 0,
      renderer: {
        labels: {
          fill: am4core.color('#4d4d4d'),
        },
      },
    },
  ],
  xAxes: [
    {
      type: 'DateAxis',
      dateFormats: {
        day: 'd MMMM',
      },
      periodChangeDateFormats: {
        day: 'd MMMM',
      },
      tooltipDateFormat: 'd MMMM (EEE)',
      renderer: {
        grid: {
          template: {
            location: 0.5,
          },
        },
        labels: {
          template: {
            location: 0.5,
            fill: am4core.color('#4d4d4d'),
          },
        },
      },
      startLocation: 0.4,
      endLocation: 0.6,
    },
  ],
  series: [
    {
      type: 'LineSeries',
      dataFields: {
        dateX: 'date',
        valueY: 'value',
      },
      tensionX: 0.8,
      stroke: '#41AAD7',
      strokeWidth: 1,
      fill: '#41B9D7',
      fillOpacity: 0.3,
      tooltip: {
        getFillFromObject: false,
        background: {
          fill: '#1b2e3e',
        },
      },
      bullets: [
        {
          type: 'CircleBullet',
          circle: {
            fill: '#41B9D7',
            stroke: '#41AAD7',
            strokeWidth: 2,
            radius: 3,
            cursorOverStyle: am4core.MouseCursorStyle.pointer,
          },
        },
      ],
    },
  ],
  cursor: {
    type: 'XYCursor',
  },
};

class Widgets {
  constructor() {
    this.widgets = {
      priorityWidget: {
        title: <Translate value="widgets.priorityWidget.title" />,
        description: <Translate value="widgets.priorityWidget.description" />,
        id: 'priorityWidget',
        types: [
          {
            engine: 'amcharts4',
            type: 'horizontalBar',
            settings: {
              field: WIDGET_PRIORITY_FIELD,
              colors: PRIORITY_COLORS,
              i18nField: `charts.${WIDGET_PRIORITY_FIELD}Types`,
              dataGenerator: generateAmchartData,
              config: HORIZONTAL_BAR_CONFIG,
              applyToChart: (chart) => {
                const series = chart.series.getIndex(0);
                series.columns.template.propertyFields.fill = 'color';
                series.columns.template.propertyFields.stroke = 'color';
              },
            },
          },
          {
            engine: 'amcharts4',
            type: 'pie',
            settings: {
              field: WIDGET_PRIORITY_FIELD,
              colors: PRIORITY_COLORS,
              i18nField: `charts.${WIDGET_PRIORITY_FIELD}Types`,
              dataGenerator: generateAmchartData,
              config: PIE_CONFIG,
              applyToChart: (chart) => {
                const series = chart.series.getIndex(0);
                series.slices.template.propertyFields.fill = 'color';
              },
            },
          },
        ],
      },
      statusWidget: {
        title: <Translate value="widgets.statusWidget.title" />,
        description: <Translate value="widgets.statusWidget.description" />,
        id: 'statusWidget',
        types: [
          {
            engine: 'amcharts4',
            type: 'horizontalBar',
            settings: {
              field: WIDGET_STATUS_FIELD,
              dataGenerator: generateAmchartData,
              max: 9,
              config: HORIZONTAL_BAR_CONFIG,
              applyToChart: (chart) => {
                const series = chart.series.getIndex(0);
                series.columns.template.fill = PINK_COLOR;
                series.columns.template.stroke = PINK_COLOR;
              },
            },
          },
          {
            engine: 'amcharts4',
            type: 'pie',
            settings: {
              field: WIDGET_STATUS_FIELD,
              dataGenerator: generateAmchartData,
              config: PIE_CONFIG,
            },
          },
        ],
      },
      registrationDynamicsWidget: {
        title: <Translate value="widgets.registrationDynamicsWidget.title" />,
        description: (
          <Translate value="widgets.registrationDynamicsWidget.description" />
        ),
        id: 'registrationDynamicsWidget',
        types: [
          {
            engine: 'amcharts4',
            type: 'line',
            settings: {
              field: 'timeline',
              filterField: WIDGET_CREATE_FIELD,
              dataGenerator: generateAmchartTimelineData,
              config: TIMELINE_CONFIG,
              applyToChart: (chart) => {
                const series = chart.series.getIndex(0);

                series.bullets.each((bullet) => {
                  if (bullet._className === 'CircleBullet') {
                    bullet.circle.tooltipText = `{dateX.formatDate('dd.MM.yyyy')} ({dateX.formatDate('EEE')})\n 
                                        ${I18n.t(
                                          'charts.countIncs',
                                        )}: [bold]{valueY.value}[/]`;

                    bullet.circle.adapter.add(
                      'tooltipText',
                      (text, target, key) => {
                        if (text) {
                          const {
                            dataContext: { timespan },
                            dateX,
                          } = target.dataItem;

                          if (timespan < 86400000) {
                            // Lesser than day
                            const startTime = `${dateX
                              .getHours()
                              .toString()
                              .padStart(2, '0')}:${dateX
                              .getMinutes()
                              .toString()
                              .padStart(2, '0')}`;
                            let endTime = new Date(dateX.getTime() + timespan);
                            endTime = `${endTime
                              .getHours()
                              .toString()
                              .padStart(2, '0')}:${endTime
                              .getMinutes()
                              .toString()
                              .padStart(2, '0')}`;

                            const splittedText = text.split('\n');
                            splittedText.splice(
                              1,
                              0,
                              `${startTime} - ${endTime}`,
                            );
                            return splittedText.join('\n');
                          }
                          return text;
                        }
                      },
                    );
                  }
                });
              },
            },
          },
        ],
      },
      categoryWidget: {
        title: <Translate value="widgets.categoryWidget.title" />,
        description: <Translate value="widgets.categoryWidget.description" />,
        id: 'categoryWidget',
        types: [
          {
            engine: 'amcharts4',
            type: 'horizontalBar',
            settings: {
              field: WIDGET_CATEGORY_FIELD,
              dataGenerator: generateAmchartData,
              max: 9,
              config: HORIZONTAL_BAR_CONFIG,
              applyToChart: (chart) => {
                const series = chart.series.getIndex(0);
                series.columns.template.fill = PINK_COLOR;
                series.columns.template.stroke = PINK_COLOR;
              },
            },
          },
          {
            engine: 'amcharts4',
            type: 'pie',
            settings: {
              field: WIDGET_CATEGORY_FIELD,
              dataGenerator: generateAmchartData,
              config: PIE_CONFIG,
            },
          },
        ],
      },
      topDomainsWidget: {
        title: <Translate value="widgets.topDomainsWidget.title" />,
        description: <Translate value="widgets.topDomainsWidget.description" />,
        id: 'topDomainsWidget',
        types: [
          {
            engine: 'amcharts4',
            type: 'horizontalBar',
            settings: {
              field: 'fqdns',
              filterField: `${WIDGET_AFFECTED_SYSTEMS}_actor`,
              dataGenerator: generateAmchartData,
              max: 5,
              config: HORIZONTAL_BAR_CONFIG,
              applyToChart: (chart) => {
                const series = chart.series.getIndex(0);
                series.columns.template.fill = BLUE_COLOR;
                series.columns.template.stroke = BLUE_COLOR;
              },
            },
          },
        ],
      },
      topIpAddressesWidget: {
        title: <Translate value="widgets.topIpAddressesWidget.title" />,
        description: (
          <Translate value="widgets.topIpAddressesWidget.description" />
        ),
        id: 'topIpAddressesWidget',
        types: [
          {
            engine: 'amcharts4',
            type: 'horizontalBar',
            settings: {
              field: 'ip_addresses',
              filterField: `${WIDGET_AFFECTED_SYSTEMS}_ip`,
              dataGenerator: generateAmchartData,
              max: 5,
              config: HORIZONTAL_BAR_CONFIG,
              applyToChart: (chart) => {
                const series = chart.series.getIndex(0);
                series.columns.template.fill = BLUE_COLOR;
                series.columns.template.stroke = BLUE_COLOR;
              },
            },
          },
        ],
      },
    };
  }

  placeWidget(dashboardLayout, dashboardColsCount, widgetId, widgetType) {
    const sortedWidgets = Object.values(dashboardLayout).sort((w1, w2) =>
      w1.y === w2.y ? w1.x - w2.x : w1.y - w2.y,
    );
    const lastRow = sortedWidgets.filter(
      (widget) => widget.y === sortedWidgets[sortedWidgets.length - 1].y,
    );

    let newWidgetX = 0;
    const newWidgetWidth = Math.ceil(dashboardColsCount / 2);

    for (const item of lastRow) {
      if (item.x - newWidgetX >= newWidgetWidth) {
        break;
      }
      newWidgetX = item.x + item.w;
    }

    if (dashboardColsCount - newWidgetX < newWidgetWidth) {
      newWidgetX = 0;
    }

    const layout = {
      i: widgetId,
      x: newWidgetX,
      y: Infinity,
      w: newWidgetWidth,
      h: 10,
      maxH: 10,
      minH: 10,
    };

    const widget = {
      id: widgetId,
      type: widgetType || this.widgets[widgetId].types[0].type,
    };

    return {
      layout,
      widget,
    };
  }

  getWidgetsList() {
    return Object.values(this.widgets);
  }

  getWidgetTypes(widgetId) {
    return this.widgets[widgetId].types.map((widgetType) => ({
      type: widgetType.type,
    }));
  }

  getWidget(id, type) {
    if (!this.widgets[id]) {
      return null;
    }

    const widget = type
      ? this.widgets[id].types.find((widget) => widget.type === type)
      : this.widgets[id].types[0];

    if (widget) {
      return {
        ...widget,
        title: this.widgets[id].title,
        id,
      };
    }
  }

  // удаляет некорректные данные пришедшие из хранилища / выставляет принудительно min/max height
  checkUserDashboard({ layout = [], widgets = {} }) {
    Object.keys(widgets).forEach((widgetId) => {
      const widget = widgets[widgetId];
      if (
        !widget ||
        !(widget instanceof Object) ||
        !this.getWidget(widget.id, widget.type)
      ) {
        delete widgets[widgetId];
      }
    });

    const allWidgetsList = Object.keys(this.widgets);
    layout.filter((widgetLayout) => {
      if (!widgetLayout || !(widgetLayout instanceof Object)) {
        return false;
      }

      return allWidgetsList.includes(widgetLayout.i);
    });

    layout.forEach((item) => {
      item.h = 10;
      item.minH = 10;
      item.maxH = 10;
    });

    return {
      widgets,
      layout,
    };
  }

  // добавляет недостающие виджеты
  checkAllWidgetExists({ layout = [], widgets = {} }, cols = 16) {
    const userWidgetsList = Object.keys(widgets);
    const allWidgetsList = Object.keys(this.widgets);
    const layoutKeys = layout.map((widgetLayout) => widgetLayout.i);

    if (
      userWidgetsList.length !== allWidgetsList.length ||
      allWidgetsList.length !== layoutKeys.length
    ) {
      allWidgetsList.forEach((widgetId) => {
        // если нет виджета в dashboardWidgets
        if (!userWidgetsList.includes(widgetId)) {
          const { layout: widgetLayout, widget } = this.placeWidget(
            layout,
            cols,
            widgetId,
          );

          layout.push(widgetLayout);
          widgets[widgetId] = widget;
        } else if (!layoutKeys.includes(widgetId)) {
          // если как-то вышло так, что виджета нет только в лейауте
          const { layout: widgetLayout } = this.placeWidget(
            layout,
            cols,
            widgetId,
          );

          layout.push(widgetLayout);
        }
      });
    }

    return {
      widgets,
      layout,
    };
  }
}

const widgets = new Widgets();
export default widgets;
